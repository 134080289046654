import React from 'react'
import Props from 'prop-types'

import Container from 'components/Styled/Container'
import DownloadButton from 'components/DownloadButton'
import Navigation from 'components/Navigation'

import ImageHand from './assets/hero.png'
import ImageHand2x from './assets/hero.png'
import ImageHand3x from './assets/hero.png'
import ImageHandAsia from './assets/hero-asian.png'
import ImageHandAsia2x from './assets/hero-asian.png'
import ImageHandAsia3x from './assets/hero-asian.png'

import { Header, Heading, MainImage, Arrow, Spacer } from './styled'

const HomepageHeader = ({ onArrowClick, translates }) => {
  const { title, buttonAppStore, buttonGooglePlay, slug } = translates

  const handleClickAppStore = () => {}

  const handleClickGooglePlay = () => {}

  return (
    <Header>
      <Navigation isMobileVisible inverted absolute translates={translates} />

      <Spacer />
      <Container>
        <Heading slug={slug}>
          <span>{title.a}</span> {title.b} <br />
          {title.c}
        </Heading>

        <div>
          <DownloadButton inverted type="apple" onClick={handleClickAppStore}>
            {buttonAppStore}
          </DownloadButton>
          <DownloadButton
            inverted
            type="google"
            onClick={handleClickGooglePlay}
          >
            {buttonGooglePlay}
          </DownloadButton>
        </div>
      </Container>
      <MainImage
        src={`${translates.slug === 'en' ? ImageHand : ImageHandAsia}`}
        srcSet={`${
          translates.slug === 'en' ? ImageHand2x : ImageHandAsia2x
        } 2x, ${translates.slug === 'en' ? ImageHand3x : ImageHandAsia3x} 3x`}
        alt="Zoe – The World’s Best Ranked Dating App for Queer Women"
      />

      <Arrow onClick={onArrowClick} />
    </Header>
  )
}

HomepageHeader.propTypes = {
  onArrowClick: Props.func.isRequired,
  translates: Props.shape({
    buttonAppStore: Props.string,
    buttonGooglePlay: Props.string,
    slug: Props.string,
    title: Props.shape({
      a: Props.string,
      b: Props.string,
      c: Props.string,
    }),
  }).isRequired,
}

export default HomepageHeader
