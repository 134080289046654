import React from 'react'
import Props from 'prop-types'
import { withProps } from 'recompose'
import { StoreBadge } from './styled'
import AppStoreLogo from './assets/app-store-logo-en.svg'
import PlayStoreLogo from './assets/play-store-logo-en.svg'

const DownloadButton = ({ children, isGoogle }) => (
  <StoreBadge>
    <a
      href="https://zoeapp.app.link/oWbj2DKgQ2"
      target="_blank"
      alt={children}
      rel="noopener noreferrer"
    >
      <img
        src={isGoogle ? PlayStoreLogo : AppStoreLogo}
        alt={children}
        width={200}
        height={60}
      />
    </a>
  </StoreBadge>
)

DownloadButton.propTypes = {
  children: Props.string.isRequired,
  isGoogle: Props.bool.isRequired,
}

export default withProps(props => ({
  isGoogle: props.type === 'google',
}))(DownloadButton)
