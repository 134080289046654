import React from 'react'
import Props from 'prop-types'
// import ReactGA from 'react-ga'

import DownloadButton from 'components/DownloadButton'
import { H2, Paragraph } from 'components/Styled/Typography'
import { breakpoints } from 'common/styles'
import IconUsers from './IconUsers'
import IconStar from './IconStar'
import IconMessages from './IconMessages'

import ImageDevice from './assets/zoe-web-samsung@2x-edited-en.jpg'
import ImageDeviceAsia from './assets/zoe-web-samsung@2x-edited.jpg'

import ImageDeviceArm from './assets/hero.png'
import ImageDeviceArmAsia from './assets/hero-asian.png'

import {
  Section,
  MainImage,
  Container,
  Content,
  Buttons,
  Numbers,
  NumbersItem,
} from './styled'

class HomepageAbout extends React.Component {
  scroll = () => {
    document.getElementById('about').scrollIntoView({ behavior: 'smooth' })
  }

  render() {
    const { translates } = this.props
    const {
      title3,
      paragraph2,
      noOfRegisteredUsers,
      messages,
      rating,
      buttonAppStore,
      buttonGooglePlay,
    } = translates

    const handleClickAppStore = () => {
      // ReactGA.ga('send', 'event', 'button', 'download', 'app-store')
    }

    const handleClickGooglePlay = () => {
      // ReactGA.ga('send', 'event', 'button', 'download', 'play-store')
    }

    return (
      <Section id="about">
        <Container>
          <Content>
            <MainImage>
              <source
                srcSet={`${
                  translates.slug === 'en' ? ImageDevice : ImageDeviceAsia
                } 2x, ${
                  translates.slug === 'en' ? ImageDevice : ImageDeviceAsia
                } 3x`}
                media={`(min-width: ${breakpoints.medium}px)`}
              />
              <source
                src={ImageDeviceArm}
                srcSet={`${
                  translates.slug === 'en' ? ImageDeviceArm : ImageDeviceArmAsia
                } 2x, ${
                  translates.slug === 'en' ? ImageDeviceArm : ImageDeviceArmAsia
                } 3x`}
                media={`(max-width: ${breakpoints.medium}px)`}
              />
              <img
                src={translates.slug === 'en' ? ImageDevice : ImageDeviceAsia}
                alt="Zoe app"
              />
            </MainImage>

            <div>
              <H2 large>{title3}</H2>
              <Paragraph large>{paragraph2}</Paragraph>

              <Numbers>
                <NumbersItem>
                  <IconUsers />
                  <h3>
                    {noOfRegisteredUsers.number}
                    <span>{noOfRegisteredUsers.text}</span>
                  </h3>
                </NumbersItem>
                <NumbersItem>
                  <IconStar />
                  <h3>
                    {rating.number}
                    <span>{rating.text}</span>
                  </h3>
                </NumbersItem>
                <NumbersItem>
                  <IconMessages />
                  <h3>
                    {messages.number}
                    <span>{messages.text}</span>
                  </h3>
                </NumbersItem>
              </Numbers>
            </div>
          </Content>

          <Buttons>
            <DownloadButton type="apple" onClick={handleClickAppStore}>
              {buttonAppStore}
            </DownloadButton>
            <DownloadButton type="google" onClick={handleClickGooglePlay}>
              {buttonGooglePlay}
            </DownloadButton>
          </Buttons>
        </Container>
      </Section>
    )
  }
}

HomepageAbout.propTypes = {
  translates: Props.shape({
    buttonAppStore: Props.string.isRequired,
    buttonGooglePlay: Props.string.isRequired,
    messages: Props.shape({
      number: Props.string,
      text: Props.string,
    }),
    noOfRegisteredUsers: Props.shape({
      number: Props.string,
      text: Props.string,
    }),
    paragraph2: Props.string.isRequired,
    rating: Props.shape({
      number: Props.string,
      text: Props.string,
    }),
    slug: Props.string.isRequired,
    title3: Props.string.isRequired,
  }).isRequired,
}

export default HomepageAbout
